<template>
  <div class="card">
    <div class="card-body">
      <b-table
          striped hover responsive
          :items="items.length ? filtered : items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
          :filter-included-fields="filterOn"
          thead-class="bg-primary text-white"
          class="mt-5"
          :select-mode="selectMode"

          ref="selectableTable"

          show-empty>


        <template slot="top-row" slot-scope="{ fields }">
          <td v-for="field in fields" :key="field.key">
            <p v-if="field.key == 'actions' || field.key == 'image' || field.key=='otp' || field.key=='bulk_trending'"></p>
            <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
          </td>
        </template>

        <template #cell(bulk_trending)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>

        <template #cell(image)="row">

          <div class="is_online">
            <img :src="userPhoto(row.item.image)" alt="image"
                 class="user_image" v-if="row.item.image" @click="showImage(row.item.image)"/>
            <img src="../../assets/img/blank.png" alt="" class="user_image" v-else>
            <i :class="`${row.item.is_online == '1' ? 'text-green ' : 'text-danger'} fas fa-circle is_online_icon`"></i>
          </div>
        </template>

        <template #cell(otp)="row">
          <div class="is_online text-center">
            <p v-if="row.item.otp===true"><i class="fas fa-check-circle text-success"></i></p>
            <p v-else><i class="fas fa-times-circle text-danger"></i></p>
          </div>
        </template>

        <template #cell(confirmation)="row">
          <div class="is_online text-center">
            <p v-if="row.item.confirmation===true"><i class="fas fa-check-circle text-success"></i></p>
            <p v-else><i class="fas fa-times-circle text-danger"></i></p>
          </div>
        </template>

        <template #cell(registration_date)="row">
          <p>{{ row.item.registration_date }}</p>
        </template>

        <template #cell(email)="row">
          <span> {{ row.item.email ? row.item.email : 'n/a' }}</span>
        </template>


        <template #cell(is_trending)="row">
          <div class="text-center">
                        <span v-if="row.item.is_trending == 1" class="text-success text-center"> <i
                            class="fas fa-street-view font-size-16 text-success trendy-icon"></i> </span>
            <span v-else class="text-danger"> <i class="fas fa-male text-danger trendy-icon"></i> </span>
          </div>
        </template>


        <template #cell(name)="row">

          <router-link :to="`/user/details/${row.item.id}`" class="font-weight-bold"> {{
              row.item.name
            }} {{ row.item.user_type ? '(' + row.item.user_type + ')' : '' }}
          </router-link>
          <div>
            <b-badge>{{ row.item.id }}</b-badge>
          </div>
        </template>

        <template #cell(username)="row">
          <span> {{ row.item.username }}</span>
        </template>

        <template #cell(actions)="row">
          <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                      class="m-2">

            <template #button-content>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <div class="dropdown-menu-fix-height">


            </div>
          </b-dropdown>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
// import {UserFilterMixin} from "../../mixins/UserFilterMixin";

import axios from "axios";

export default {
  name: "UserFilter",

  data() {
    return {

      downloadLoading: false,
      filename: '',
      autoWidth: true,
      bookType: 'xlsx',
      districts: [],
      thanas: [],
      json_fields: {
        "name": {
          field: "name",
        },
        "slug": {
          field: "slug",
        },
        "Email": {
          field: "email",
        },
        "Phone number": {
          field: "phone_number",
        },
        "Registration date": {
          field: "registration_date",
        },
        "Is confirmed ? ": {
          field: 'confirmation',
          callback: (value) => {
            return value == true ? 'valid' : 'invalid';
          },
        },

      },
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Slug",
          field: "slug",
        },
        {
          label: "Phone number",
          field: "phone_number",
        },
        {
          label: "Is confirmed ? ",
          field: "confirmation",
        },
        {
          label: "Is otp verified",
          field: "otp",
        },
        {
          label: "Registration date",
          field: "registration_date",
        },
      ],
      loading: true,
      currentPage: 0,
      perPage: 20,
      pageOptions: [20, 40, 60, {value: 100, text: "Show a lot"}],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      localActive: false,
      nameState: null,
      emailState: null,
      users: [],
      items: [],
      fields: [
        {
          key: 'bulk_trending',
          label: '',
        },
        {
          key: 'image',
          label: 'Image',
          sortable: true
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true
        },
        {
          key: 'username',
          label: 'Username',
          sortable: true
        },
        {
          key: 'slug',
          label: 'Slug',
          sortable: true
        },
        {
          key: 'phone_number',
          label: 'Phone number',
          sortable: true
        },
        {
          key: 'confirmation',
          label: 'Is confirmed?',
          sortable: true,
        },
        {
          key: 'otp',
          label: 'Is otp verified?',
          sortable: true,
        },
        {
          key: 'registration_date',
          label: 'Reg date',
          sortable: true,
        },
        {
          key: 'last_update_date',
          label: 'Updated date',
          sortable: true,
        },
      ],
      filter: null,
      filterOn: [],
      filters: {
        id: '',
        name: '',
        username: '',
        phone_number: '',
        confirmation: '',
        otp: '',
        registration_date: '',
        slug: '',
      },
      startDate: null,
      endDate: null,
      show: true,
      form: {
        comment: '',
        module_type: '',
        password: '',
      },
      base_url: process.env.VUE_APP_API_URL,
      user_id: '',
      callHistories: [],
      call_history_filed: [
        {
          label: 'Sl',
          key: 'index',
          sortable: true,
        },
        {
          label: 'Orko user',
          key: 'fullname',
          sortable: true,
        },
        {
          label: 'User type',
          key: 'user_type',
          sortable: true,
        },
        {
          label: 'Call category',
          key: 'call_category',
          sortable: true,
        },
        {
          label: 'Call Type',
          key: 'call_type',
          sortable: true,
        },
        {
          label: 'Call Status',
          key: 'call_status',
          sortable: true,
        },
        {
          label: 'Call Response',
          key: 'call_response',
          sortable: true,
        },
        {
          label: 'Reminder date',
          key: 'reminder_date',
          sortable: true,
        },
        {
          label: 'Call action date',
          key: 'call_action_date',
          sortable: true
        },
        {
          label: 'Remarks',
          key: 'remarks',
          sortable: true
        },
        {
          label: 'Created at',
          key: 'created_at',
          sortable: true
        }

      ],
      call_history: {
        call_response: '',
        call_type: '',
        remarks: '',
        call_category: '',
        reminder_date: '',
        is_reminder: '',
      },
      call_category: [],
      user: {
        user_name: '',
        user_id: ''
      },
      points: [],
      total_point: '',
      point_fields: [
        {
          label: 'Point type',
          key: 'point_type_description',
        },
        {
          label: 'No of occurrence',
          key: 'no_of_occurrence',
        },
        {
          label: 'Point earned',
          key: 'point_earned',
        },

      ],
      user_name: '',
      user_phone_number: '',
      user_image: null,
      doctor_id: '',
      call_log: {},
      agent_type: 'agent_pharmacy',
      partent_sub_types: [],
      parient_id: '',
      is_trending: [],
      trending_submit_btn: false,
      selectMode: 'multi',
      selected: [],
      userFilterKey: 'all',
      trendy: false,
      qc: false,
      total_doctor: 0,
      newDoctor: {
        first_name: '',
        last_name: '',
        phone_number: '',
        gender: 'male',
        user_type: 'doctor',
        country_id: '18',
        password: '',
        sub_type: 'physio',
      },
      fullNameState: null,
      passwordState: null,
      phoneNumberState: null,
      genderState: null,
      gender: [
        {text: 'Male', value: 'male'},
        {text: 'Female', value: 'female'},
        {text: 'Other', value: 'other'},
      ],
      sub_types: [
        {value: null, text: 'Please select sub-type'},
        {value: 'physio', text: 'Physio'},
      ]

    }
  },
  created() {

    this.$root.$on('search_text', (msg) => {
      if (msg) {
        this.searchDoctor(msg);
      }
    });
    this.searchDoctor(this.$route.query.search_text);
  },
  computed: {
    rows() {
      return this.users.length
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key}
          })
    },

    filtered() {
      let startDate = this.localizeDate(this.startDate);
      let endDate = this.localizeDate(this.endDate);

      const filtered = this.items.filter(item => {
        const itemDate = new Date(item.registration_date)
        if (startDate && endDate) {
          return startDate <= itemDate && itemDate <= endDate;
        }
        if (startDate && !endDate) {
          return startDate <= itemDate;
        }
        if (!startDate && endDate) {
          return itemDate <= endDate;
        }


        return Object.keys(this.filters).every(key =>
            String(item[key]).includes(this.filters[key]))
      })


      return filtered.length > 0 ? filtered : [{
        id: '',
        name: '',
        phone_number: '',
        confirmation: '',
        otp: '',
        registration_date: '',
        slug: '',
      }]
    },

    filteredData() {

      let filterType = this.selectedType;
      let startDate = this.localizeDate(this.startDate);
      let endDate = this.localizeDate(this.endDate);

      const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
      return itemsByType
          .filter(item => {
            const itemDate = new Date(item.registration_date)
            if (startDate && endDate) {
              return startDate <= itemDate && itemDate <= endDate;
            }
            if (startDate && !endDate) {
              return startDate <= itemDate;
            }
            if (!startDate && endDate) {
              return itemDate <= endDate;
            }
            return true;
          })
    },
    routeName() {
      return this.$route.name
    },


  },
  methods: {
    searchDoctor(text) {
      this.loading = true
      let form = new FormData();
      form.append('limit', '100');
      form.append('offset', '0');
      form.append('search_text', text);
      axios.post(`${process.env.VUE_APP_URL}/api/v1/user/filter/list?api_token=${localStorage.getItem('api_token')}`, form)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied');
            } else {

              this.loading = false;
              this.items = response.data.data;
              this.total_doctor = response.data.total_count;
            }
          })
    },
    onRowSelected(items) {
      this.selected = items
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return '../../../public/media/users/blank.png'
      }
    },
    showImage(img) {
      const h = this.$createElement
      // More complex structure
      const messageVNode = h('div', {class: ['foobar']}, [
        h('b-img', {
          props: {
            src: `${process.env.VUE_APP_API_URL}/${img}`,
            thumbnail: true,
            center: false,
          }
        })
      ])
      // We must pass the generated VNodes as arrays
      this.$bvModal.msgBoxOk([messageVNode], {
        buttonSize: 'sm',
        centered: true, size: 'lg'
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
    localizeDate(date) {
      if (!date || !date.includes('-')) return date
      const [yyyy, mm, dd] = date.split('-')
      return new Date(`${mm}/${dd}/${yyyy}`)
    },
  }

}
</script>

<style scoped>

</style>